@use '../color/colors' as *;
@use '../mixin';

.text-link,
.link-link {
    @include mixin.btn-color(
        transparent,
        transparent,
        $color4,
        transparent,
        transparent,
        $color4
    );

    &:hover {
        text-decoration: none;
    }

    img {
        transition: 0.15s ease-in-out;
    }

    &:hover img {
        transform: translate(0.3rem, 0);
    }
}
