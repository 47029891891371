@use '../color/colors' as *;

// menu mobile
.main-header nav {
    width: 100%;

    @media (max-width: 991px) {
        background-color: $color1;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15);
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: all 0.15s ease-in-out;
        z-index: 10;
    }

    @media (min-width: 992px) {
        display: block;
        margin-top: 0;
        width: auto;
    }
}

.show-body .main-header nav {
    @media (max-width: 991px) {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: top;
    }
}

#toggleMenu {
    display: block;
    position: relative;
    width: 25px;

    @media (min-width: 992px) {
        display: none;
    }
}

.menu-icon {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}

#closeMenu {
    transform: scale(0);
    transition: all 0.15s ease-in-out;
}

#openMenuWhite {
    transform: scale(0);
}

#openMenuBlack {
    transform: scale(1);
    transition: all 0.15s ease-in-out;
}

.show-body {
    #openMenuBlack {
        transform: scale(0);
    }

    #closeMenu {
        transform: scale(1);
    }

    .main-header {
        background-color: $color1;
    }
}
