@use 'color/colors' as *;
@use 'font/fonts' as *;

html {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 15px;
}

body {
    color: $color4;
}

p a {
    color: $color4;
    text-decoration: underline;

    &:hover {
        color: $color4;
        text-decoration: none;
    }
}

strong {
    font-family: 'Lato-Bold';
}
