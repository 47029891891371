// header-container
.header-container {
    width: 100%;

    > .row {
        margin: 0;
        padding: 0;
    }

    .col {
        padding: 0;
    }

    .header-mobile {
        width: 100%;
    }

    .logo {
        margin-left: 15px;
    }

    #toggleMenu {
        cursor: pointer;
        height: 50px;
        width: 50px;
    }

    @media (min-width: 576px) {
        max-width: 100%;

        .header-mobile {
            margin: auto;
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .header-mobile {
            max-width: calc(100% - 4rem);
        }
    }

    @media (min-width: 992px) {
        margin-left: auto;
        margin-right: auto;
        max-width: calc(100% - 4rem);

        .header-mobile {
            margin-left: 0;
            margin-right: 3rem;
            max-width: 200px;
        }
    }

    @media (min-width: 1200px) {
        max-width: 1200px;

        .header-mobile {
            margin-right: 5.8rem;
        }
    }
}
