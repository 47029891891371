@use '../color/colors' as *;

footer {
    a {
        box-shadow: 0 2px 0 0 $footer1;
        margin: 3px 0;
        transition: all 0.15s ease-in-out;

        &:hover,
        &:focus {
            box-shadow: 0 2px 0 0 $footer2;
            color: $footer2;
            text-decoration: none;
        }

        &.text-decoration-none:hover,
        &.text-decoration-none:focus {
            box-shadow: none;
            text-decoration: none;
        }
    }
}
