@use '../color/colors' as *;
@use '../font/fonts' as *;

// nav desktop all pages
.page-navigation {
    &.container {
        @media (min-width: 992px) {
            margin: 0;
            max-width: 100%;
            padding: 0;
        }
    }

    li {
        display: inline-block;
        margin: 0 1rem;
        padding: 0.7rem 0;
    }

    a {
        color: $color4;
        cursor: pointer;
        display: inline-block;
        font-size: 1.2rem;
        margin: 0 0.9em;
        padding: 2px;
        position: relative;
        transition: all 0.15s ease-in-out;

        &:hover {
            box-shadow: 0 2px 0 0 $secondary1;
            text-decoration: none;
        }
    }

    .undermenu {
        background-color: $color1;
        border-radius: 3px;
        box-shadow: 0 6px 10px 0 rgba(139, 139, 139, 0.5);
        font-family: 'Lato-Regular', sans-serif;
        opacity: 0;
        padding: 15px 0;
        position: absolute;
        transform: translate(-9px, 11px);
        transition: 0.15s ease-in-out;
        visibility: hidden;
        z-index: 9;

        @media (min-width: 992px) {
            border: 1px solid $border1;
        }

        li {
            display: block;
            margin: 0;
            padding: 0;

            a {
                background-position: 23px center;
                background-repeat: no-repeat;
                background-size: auto 26px;
                box-shadow: 0 2px 0 0 $color1;
                color: $color4;
                display: block;
                font-family: 'Lato-Regular', sans-serif;
                line-height: 1;
                margin: 0;
                padding: 17px 15px 14px 63px;
                transition: all 0.15s ease-in-out;
                width: 100%;

                &:hover {
                    background-color: $primary4;
                }
            }

            &:first-of-type a {
                background-image: url('../img/domain_registry.svg');
            }

            &:nth-of-type(2) a {
                background-image: url('../img/dns_software.svg');
            }

            &:nth-of-type(3) a {
                background-image: url('../img/dns_anycast.svg');
            }

            &:nth-of-type(4) a {
                background-image: url('../img/dns_statistic.svg');
            }
        }

        @media (min-width: 992px) {
            &::after,
            &::before {
                border: solid transparent;
                bottom: 100%;
                content: '';
                height: 0;
                left: 2.1rem;
                pointer-events: none;
                position: absolute;
                width: 0;
            }

            &::after {
                border-color: transparent;
                border-bottom-color: $color1;
                border-width: 5px;
                margin-left: -5px;
            }

            &::before {
                border-color: transparent;
                border-bottom-color: $border1;
                border-width: 6px;
                margin-left: -6px;
            }
        }
    }

    li:hover .undermenu,
    li:focus .undermenu {
        opacity: 1;
        visibility: visible;
    }
}
