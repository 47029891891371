@use '../color/colors' as *;
@use '../font/fonts' as *;

// nav mobile all pages
@media (max-width: 991px) {
    .page-navigation {
        li {
            display: block;
            margin: 0;
            padding: 0.475rem 0;
        }

        a {
            color: $color4;
            display: inline-block;
            font-family: 'Lato-Regular', sans-serif;
            font-size: 1.05rem;
            padding: 0.85rem 0;
        }

        .undermenu {
            background-color: transparent;
            border-radius: 0;
            box-shadow: none;
            opacity: 1;
            padding: 0;
            position: relative;
            transform: translate(0, 0);
            visibility: visible;

            li {
                margin-left: 1rem;

                a {
                    box-shadow: none;
                    line-height: 1;
                    margin-left: 0 1rem;
                    width: auto;
                }
            }
        }
    }
}
