@use '../color/colors' as *;
@use '../mixin';

//buttons
.btn {
    border-radius: 3px;
    font-size: 1rem;
    line-height: 1;
    padding: 0.935rem 2rem;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

.btn.focus,
.btn:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show > .btn.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-primary {
    @include mixin.btn-color(
        $primary2,
        $primary2,
        $color1,
        $primary3,
        $primary3,
        $color1
    );

    &:focus,
    &:active {
        background-color: $primary1;
        border-color: $primary1;
    }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    background-color: $primary1;
    border-color: $primary1;
}

.btn-secondary {
    @include mixin.btn-color(
        $secondary1,
        $secondary1,
        $color4,
        $secondary2,
        $secondary2,
        $color4
    );

    &:focus,
    &:active {
        background-color: $secondary3;
        border-color: $secondary3;
        color: $color1;
    }

    &:hover:disabled,
    &:focus:disabled,
    &:active:disabled {
        background-color: $color3;
        border-color: $color3;
        color: $color1;
    }
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
    background-color: $secondary3;
    border-color: $secondary3;
    color: $color1;
}

.btn-light {
    @include mixin.btn-color(
        $color1,
        $color1,
        $primary2,
        $primary4,
        $primary4,
        $primary2
    );

    &:focus,
    &:active {
        background-color: $primary1;
        border-color: $primary1;
        color: $color1;
    }
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
    background-color: $primary4;
    border-color: $primary4;
    color: $primary2;
}

.btn-info {
    @include mixin.btn-color(
        $color1,
        $primary2,
        $primary2,
        $primary4,
        $primary2,
        $primary2
    );

    &:focus,
    &:active {
        background-color: $primary1;
        border-color: $primary1;
        color: $color1;
    }
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
    background-color: $primary4;
    border-color: $primary2;
    color: $primary2;
}

.btn-link {
    @include mixin.btn-color(
        $color1,
        $color1,
        $color4,
        $primary4,
        $primary4,
        $color4
    );

    &:focus,
    &:active {
        background-color: $primary1;
        border-color: $primary1;
        color: $color1;
    }
}

.btn-link:not(:disabled):not(.disabled).active,
.btn-link:not(:disabled):not(.disabled):active,
.show > .btn-link.dropdown-toggle {
    background-color: $primary4;
    border-color: $primary4;
    color: $color4;
}

.btn-sm,
.btn-sm * {
    font-size: 16px;
    line-height: 1.5;
    padding: 0;
}
