@use '../color/colors' as *;

//tables
.table-bordered td,
.table-bordered th {
    border: 1px solid $table1;

    &:first-of-type {
        border-left: 0;
    }

    &:last-of-type {
        border-right: 0;
    }
}

.table > :not(caption) > * > * {
    background-color: inherit;
}
