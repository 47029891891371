@use '../color/colors' as *;

.table-responsive {
    table {
        position: relative;

        tbody td {
            &:first-of-type {
                background-color: $color1;
                left: 0;
                max-height: 100%;
                position: sticky;
                z-index: 9;

                @media (min-width: 768px) {
                    background-color: transparent;
                    position: relative;
                }
            }
        }

        thead th {
            &:first-of-type {
                background-color: $primary2;
                left: 0;
                position: sticky;
                top: 0;
                z-index: 9;

                @media (min-width: 768px) {
                    position: relative;
                }
            }
        }

        thead.bg-primary1 th:first-of-type {
            background-color: $primary1;
        }
    }
}
