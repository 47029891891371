@use './fonts' as *;
@use '../mixin';

//Title h 1-6 and display size
$min-width: 320px;
$max-width: 768px;

h1 {
    @include mixin.fluid-type($min-width, $max-width, 24px, 40px);

    font-family: 'Lato-Bold', sans-serif;
    line-height: 1.2;
}

h2 {
    @include mixin.fluid-type($min-width, $max-width, 22px, 38px);

    font-family: 'Lato-Bold', sans-serif;
    line-height: 1.25;
}

h3 {
    @include mixin.fluid-type($min-width, $max-width, 20px, 30px);

    font-family: 'Lato-Regular', sans-serif;
    line-height: 1.2;
}

h4 {
    @include mixin.fluid-type($min-width, $max-width, 18px, 20px);

    font-family: 'Lato-Regular', sans-serif;
    line-height: 1.6;
}

p {
    @include mixin.fluid-type($min-width, $max-width, 16px, 20px);

    font-family: 'Lato-Regular', sans-serif;
    line-height: 1.6;
}

.display-1,
.display-1 * {
    @include mixin.fluid-type($min-width, $max-width, 30px, 40px);

    font-family: 'Lato-Bold', sans-serif;
    line-height: 1.2;
}

.display-2,
.display-2 * {
    @include mixin.fluid-type($min-width, $max-width, 28px, 38px);

    font-family: 'Lato-Bold', sans-serif;
    line-height: 1.25;
}

.display-3,
.display-3 * {
    @include mixin.fluid-type($min-width, $max-width, 20px, 30px);

    font-family: 'Lato-Regular', sans-serif;
    line-height: 1.2;
}

.display-4,
.display-4 * {
    @include mixin.fluid-type($min-width, $max-width, 18px, 20px);

    font-family: 'Lato-Regular', sans-serif;
    line-height: 1.6;
}

.display-p1,
.display-p1 * {
    @include mixin.fluid-type($min-width, $max-width, 16px, 20px);

    font-family: 'Lato-Regular', sans-serif;
    line-height: 1.6;
}

.display-p2,
.display-p2 * {
    @include mixin.fluid-type($min-width, $max-width, 16px, 17px);

    font-family: 'Lato-Regular', sans-serif;
    line-height: 1.65;
}

.display-p3,
.display-p3 * {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 16px;
    line-height: 1.5;
}

.display-p4,
.display-p4 * {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 1.714;
}

.display-p5,
.display-p5 * {
    @include mixin.fluid-type($min-width, $max-width, 16px, 17px);

    font-family: 'Lato-Regular', sans-serif;
    line-height: 1.65;
}

.line-height-1 * {
    line-height: 1;
}

.line-height-1-2 * {
    line-height: 1.2;
}

.line-height-30 * {
    line-height: 2rem;
}
