//
// FONT Lato
// ------------------------------------------------------
@font-face {
    font-family: 'Lato-Black';
    src: url('../fonts/Lato-Black.woff'), url('../fonts/Lato-Black.ttf');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('../fonts/Lato-Bold.woff'), url('../fonts/Lato-Bold.ttf');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../fonts/Lato-Regular.woff'), url('../fonts/Lato-Regular.ttf');
}

@font-face {
    font-family: 'Lato-Light';
    src: url('../fonts/Lato-Light.woff'), url('../fonts/Lato-Light.ttf');
}

@font-face {
    font-family: 'Lato-Thin';
    src: url('../fonts/Lato-Thin.woff'), url('../fonts/Lato-Thin.ttf');
}
