@use '../color/colors' as *;
@use '../variable' as var;

.checkbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > p {
        color: $color4;
        padding-bottom: 3rem;
        width: 100%;
    }

    label {
        color: $color4;
        font-size: 16px;
        left: var.$left-margin;
        line-height: 32px;
        position: relative;
        width: calc(100% - 32px);

        @media (min-width: 768px) {
            font-size: 20px;
            width: calc(50% - 15px);
        }
    }

    .text-danger {
        //because bootstrap using !important
        color: $danger1 !important;
    }

    abbr[title] {
        color: $color3;
    }
}
