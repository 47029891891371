@use 'colors' as *;

// font colors
.text-color1 * {
    color: $color1;
}

.text-color2 * {
    color: $color2;
}

.text-color3 * {
    color: $color3;
}

.text-color4 * {
    color: $color4;
}

.text-primary1 * {
    color: $primary1;
}

.text-primary2 * {
    color: $primary2;
}

.text-primary3 * {
    color: $primary3;
}

.text-primary4 * {
    color: $primary4;
}

.text-secondary1 * {
    color: $secondary1;
}

.text-secondary2 * {
    color: $secondary2;
}

.text-secondary3 * {
    color: $secondary3;
}

.text-footer1 * {
    color: $footer1;
}

.text-footer2 * {
    color: $footer2;
}

.text-footer3 * {
    color: $footer3;
}
