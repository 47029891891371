@use '../color/colors' as *;

input,
textarea,
select {
    border: 1px solid $border1;
    border-radius: 3px;
    color: $color4;
    padding: 0.66rem 0.4rem;

    &:disabled {
        background-color: $input1;
    }

    &[readonly] {
        background-color: $input1;
    }
}

input[type='checkbox']:disabled::before {
    background-color: $input1;
}

input[type='checkbox'][readonly]::before {
    background-color: $input1;
}

.base-form {
    font-size: 20px;
    line-height: 1;

    @media (max-width: 767px) {
        font-size: 16px;
    }

    label {
        color: $primary2;
        display: block;
        font-size: 14px;
        line-height: 32px;

        @media (max-width: 767px) {
            font-size: 12px;
        }
    }

    .d-flex aside {
        width: 100%;

        @media (min-width: 768px) {
            width: 50%;
        }
    }

    textarea {
        height: 11rem;
        width: 100%;
    }

    input {
        width: 100%;
    }

    ::placeholder,
    a,
    p {
        color: $color3;
    }

    input[type='file'] {
        border: 0;
    }

    input[type=file].drag-and-drop {
        background-color: $border1;
        background-image: url('../img/upload-file.svg');
        background-position: 88% center;
        background-repeat: no-repeat;
        border: 2px dashed $footer2;
        border-radius: 0.2em;
        margin-bottom: 1em;
        padding: 2em;
        width: 100%;
    }

}

ul[id*='id_radioselect'] {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    label {
        color: $color3;
        font-size: 20px;
        line-height: 32px;

        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

    li {
        width: 100%;

        @media (min-width: 768px) {
            width: calc(50% - 15px);
        }
    }

    input {
        width: auto;
    }
}

abbr[title] {
    color: $primary2;
    margin-left: -2px;
    text-decoration: none;
}

.text-danger,
.errormessages {
    color: $danger1;
    display: block;
    font-size: 14px;
    line-height: 32px;

    @media (max-width: 767px) {
        font-size: 12px;
    }
}

.text-danger.aldryn-forms-submit-msg {
    //because bootstrap using !important
    color: $color4 !important;
    font-size: 20px;
    margin-top: 2rem;
    text-align: center;

    &::before {
        content: url('../img/rolling.svg');
        display: inline-block;
        transform: translate(-6px, 6px);
    }
}

.form-required {
    position: relative;
}

.text-danger.aldryn-forms-required-msg {
    position: absolute;
}

.aldryn-form-success-message.markdown,
.cms-form-success-message {
    margin-top: 3rem;
    text-align: center;

    @media (min-width: 1600px) {
        margin-top: 8rem;
    }

    > p {
        margin-top: 2rem;

        .btn {
            display: block;
        }
    }

    .btn {
        color: $color4;
        margin: 3rem auto 1.5rem;
        text-decoration: none;

        @media (min-width: 1600px) {
            margin-top: 9rem;
        }
    }

    &::before {
        content: url('../img/email-send.svg');
    }
}
