@media only screen and (min-resolution: 2dppx) and (min-width: 992px) and (max-width: 1366px) {
    html {
        font-size: 12px;
    }

    body {
        h1,
        .display-1,
        .display-1 * {
            font-size: 32px;
        }

        h2,
        .display-2,
        .display-2 * {
            font-size: 30.4px;
        }

        h3,
        .display-3,
        .display-3 * {
            font-size: 24px;
        }

        h4,
        p,
        .display-p1,
        .display-p1 * {
            font-size: 16px;
        }

        .display-p2,
        .display-p2 *,
        .display-p3,
        .display-p3 *,
        .display-p5,
        .display-p5 * {
            font-size: 13px;

        }

        .display-p4,
        .display-p4 * {
            font-size: 11px;
        }
    }

    .img-mobile-50 img {
        height: 112px;
        width: 112px;
    }

    input,
    textarea {
        font-size: 16px;
    }
}

@media only screen and (min-resolution: 2dppx) and (min-width: 1200px) and (max-width: 1366px) {
    .header-container {
        max-width: 960px;
    }

    .container {
        max-width: 960px;
    }
}
