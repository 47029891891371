@use '../color/colors.scss' as *;

.carousel-indicators {
    button[data-bs-target] {
        background-color: $color3;
        cursor: pointer;

        &.active {
            background-color: $primary2;
            cursor: auto;
        }
    }
}

.carousel-control-prev,
.carousel-control-next {
    opacity: 1;

    &:hover {
        opacity: 1;
    }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-image: url("../img/arrow.svg");
    height: 2.7rem;
    width: 2.7rem;

    &:hover {
        background-image: url("../img/arrow-light.svg");
    }
}

.carousel-control-next-icon {
    transform: rotate(180deg);
}

.carousel {
    display: flex;
    flex-direction: column;

    .carousel-inner {
        margin: auto;
        order: 1;
        width: 90%;
    }

    .carousel-control-next,
    .carousel-control-prev {
        height: 156px;
        width: 5%;

        @media (min-width: 576px) {
            height: 230px;
        }

        @media (min-width: 768px) {
            height: 308px;

        }

        @media (min-width: 992px) {
            height: 450px;
        }

        @media (min-width: 1200px) {
            height: 528px;
        }
    }

    .carousel-caption {
        min-height: 200px;
        position: unset;

        @media (min-width: 576px) {
            min-height: 240px;
        }

        @media (min-width: 768px) {
            min-height: 180px;
        }

        @media (min-width: 1200px) {
            min-height: 140px;
        }

        &.d-none.d-md-block {
            display: block !important;
        }
    }

    .carousel-indicators {
        margin: 1.4rem 0 0;
        order: 2;
        position: unset;
    }
}
