@use '../color/colors' as *;
@use '../variable' as var;

//radio appearance
ul[id*='id_radioselect'] {
    label {
        left: var.$left-margin;
        position: relative;
        width: calc(100% - 32px);
    }
}

.checkbox {
    input {
        position: absolute;
    }
}

input[type='radio'],
input[type='checkbox'] {
    appearance: none;
    border: 0;
    box-shadow: none;
}

input[type='radio']::before,
input[type='radio']::after,
input[type='checkbox']::before,
input[type='checkbox']::after {
    content: '';
    display: inline-block;
    left: - var.$left-margin;
    position: absolute;
    top: 7.5px;
}

input[type='radio']::before,
input[type='checkbox']::before {
    border: 1px solid $border1;
    border-radius: 3px;
    height: 18px;
    width: 18px;
}

label input[type='radio']:checked::before,
label input[type='checkbox']:checked::before {
    background-color: $primary2;
    border: 1px solid $primary2;
}

input[type='radio']::after,
input[type='checkbox']::after {
    border-bottom: 2px solid $color1;
    border-left: 2px solid $color1;
    height: 8px;
    transform: rotate(-45deg) translate(-1px, 4px);
    width: 14px;
}

label input[type='radio']::after,
label input[type='checkbox']::after {
    content: none;
}

label input[type='radio']:checked::after,
label input[type='checkbox']:checked::after {
    content: '';
}

.has-error {
    input[type='radio']::before,
    input[type='checkbox']::before {
        border-color: $danger2;
    }

    input[type='radio'].checkbox-ok::before,
    input[type='checkbox'].checkbox-ok::before {
        border-color: $border1;
    }

    label input[type='radio'].checkbox-ok:checked::before,
    label input[type='checkbox'].checkbox-ok:checked::before {
        background-color: $primary2;
        border: 1px solid $primary2;
    }
}

//label hidden
.label-hidden {
    input,
    textarea {
        background-clip: padding-box;
        box-shadow: -2px -30px 0 -2px $color2;

        &:not(:placeholder-shown) {
            border-color: $border1;
            box-shadow: -2px -30px 0 -2px rgba(255, 255, 255, 0);
        }

        ul.errormessages {
            display: block;
        }

        &:not(:placeholder-shown) + ul.errormessages {
            display: none;
        }

        &.email-error {
            &:not(:placeholder-shown) {
                border-color: $danger2;
                box-shadow: -2px -30px 0 -2px $color2;
            }

            &:not(:placeholder-shown) + ul.errormessages {
                display: block;
            }
        }
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-text-fill-color: $color4;

        &:not(:placeholder-shown) + ul.errormessages {
            display: none;
        }
    }
}

.label-hidden {
    display: flex;
    flex-direction: column;
    position: relative;

    ul.errormessages {
        order: 1;
        position: absolute;
        z-index: 9;
    }

    label {
        order: 2;
    }

    input,
    textarea {
        order: 3;
    }
}

.has-error {
    border-color: $danger2;
    position: relative;

    .text-danger {
        position: absolute;
        top: 3rem;
    }
}

.published-or-draft.content-draft .input-box,
.published-or-draft.content-draft .more-content {
    border: 2px dashed $danger2;
    padding: 1em;
}

.published-or-draft.content-published .input-box,
.published-or-draft.content-published .more-content {
    display: none;
}
