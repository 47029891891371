@use '../color/colors' as *;
@use '../font/fonts' as *;

.main-header {
    background-color: $color1;
    font-family: 'Lato-Bold', sans-serif;

    @media (max-width: 991px) {
        max-height: 60px;
    }
}
