//logo
.logo {
    height: 36px;
    margin: 12px 3rem 12px 0;

    @media (min-width: 992px) {
        height: 60px;
        margin: 20px 3rem 20px 0;
    }

    @media (min-width: 1200px) {
        margin-right: 6.8rem;
    }
}
