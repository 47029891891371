@use '../color/colors.scss' as *;

.margin-no * {
    margin: 0;
}

.padding-no * {
    padding: 0;
}

.like-another-yellow-box {
    @media (max-width: 575px) {
        figure img {
            height: 26px;
        }

        h3 {
            font-size: 18px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }

        a img {
            height: 10px;
            width: 15px;
        }
    }
}

.hover-secondary {
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: $secondary2;
    }
}

.title-over {
    @media (min-width: 992px) {
        width: 135%;
    }

    @media (min-width: 1200px) {
        width: 115%;
    }
}

.title-over-xl {
    @media (min-width: 992px) {
        width: 145%;
    }

    @media (min-width: 1200px) {
        width: 130%;
    }
}

.title-cards {
    margin-top: 8rem;

    @media (max-width: 767px) {
        margin-top: 0;
        text-align: center;
    }
}

.cards-logo {
    height: 7rem;
}

.my-xxl {
    margin-bottom: 2.7rem;
    margin-top: 1.4rem;

    @media (min-width: 991px) {
        margin-bottom: 8rem;
        margin-top: 8.4rem;
    }
}

.title-cards-my {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

    @media (min-width: 768px) {
        margin-bottom: 7rem;
        margin-top: 7rem;
    }
}

.mt-xxl {
    margin-top: 2rem;

    @media (min-width: 768px) {
        margin-top: 6.66rem;
    }
}

.mb-xxl {
    margin-bottom: 2.7rem;

    @media (min-width: 768px) {
        margin-bottom: 8rem;
    }
}

.pt-xl {
    padding-top: 1.4rem;

    @media (min-width: 768px) {
        padding-top: 4.4rem;
    }
}

.px-small {
    padding-left: 1.33rem;
    padding-right: 1.33rem;

    @media (min-width: 768px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.pt-xxl {
    padding-top: 2rem;

    @media (min-width: 768px) {
        padding-top: 6.66rem;
    }
}

.pb-xxl {
    padding-bottom: 2.7rem;

    @media (min-width: 768px) {
        padding-bottom: 8rem;
    }
}

.p-box {
    padding: 1.25rem;

    @media (min-width: 768px) {
        padding: 4rem;
    }
}

.img-bottom-line img {
    transform: translate(0, 3px);
}

.overflow-hidden {
    overflow-y: hidden;
}

.mw-md-50 * {
    @media (max-width: 991px) {
        max-width: 50%;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: calc(100% - 4rem);
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.img-mobile-50 img {
    height: 140px;
    width: 140px;

    @media (max-width: 991px) {
        height: 80px;
        width: 80px;
    }
}

.mw-100-img img {
    max-width: 100%;
}

.height500 {
    height: 500px;
}

.height1 {
    height: 1px;
}

.img-over img {
    @media (min-width: 1390px) {
        width: 114%;
    }
}

.w-20 {
    width: 20rem;
}

.success p {
    display: none;
}

.white-space-pre {
    white-space: pre;
}
