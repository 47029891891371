$color1: #fff;
$color2: #f8f8f8;
$color3: #717171;
$color4: #000;

$primary1: #0b347c;
$primary2: #3d7dfc;
$primary3: #80aaff;
$primary4: #eaf0ff;

$secondary1: #fabd24;
$secondary2: #ffdc87;
$secondary3: #da9c00;

$table1: #72a0fc;
$table2: #fefefe;

$border1: #d4d4d4;
$danger1: #b60000;
$danger2: #c60000;

$footer1: #404040;
$footer2: #c3c3c3;
$footer3: #6f6f6f;

$input1: #ededed;
