@use '../color/colors' as *;

//tables
thead {
    background-color: $primary2;
}

.table {
    background-color: $table2;
    margin-bottom: 0;
    text-align: center;

    thead th {
        border: 0;
        color: $color1;
        font-size: 1.3rem;
        padding: 0.8rem;

        &:first-of-type {
            text-align: left;
        }
    }

    tbody td {
        color: $primary1;
        font-size: 1.3rem;
        padding: 0.8rem;

        &:first-of-type {
            text-align: left;
        }
    }
}
