@use 'colors' as *;

//background color
.bg-color1 {
    background-color: $color1;
}

.bg-color2 {
    background-color: $color2;
}

.bg-color3 {
    background-color: $color3;
}

.bg-color4 {
    background-color: $color4;
}

.bg-primary1 {
    background-color: $primary1;
}

.bg-primary2 {
    background-color: $primary2;
}

.bg-primary3 {
    background-color: $primary3;
}

.bg-primary4 {
    background-color: $primary4;
}

.bg-secondary1 {
    background-color: $secondary1;
}

.bg-secondary2 {
    background-color: $secondary2;
}

.bg-secondary3 {
    background-color: $secondary3;
}

.bg-footer1 {
    background-color: $footer1;
}

.bg-footer2 {
    background-color: $footer2;
}

.bg-footer3 {
    background-color: $footer3;
}
