@media (min-width: 768px) {
    .ml-md-5, .mx-md-5 {
        margin-left: 3rem !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 3rem !important;
    }
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}
